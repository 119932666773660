<template lang="pug">
  tr
    td.table-item
      span {{ template.template_name }}
    td.table-item
      span {{ template.created_at }}
    td.table-item
      span {{ createInitiator }}
    td.table-item
      span {{ template.updated_at }}
    td.table-item
      span {{ updateInitiator }}
    td.table-item
      FaIcon.action-delete(
        icon="trash-alt",
        @click="deleteItem"
      )
</template>

<script>
  // mixin
  import withConfirmation from "@/mixins/withConfirmation"

  export default {
    props: {
      template: Object,
      index: Number
    },

    mixins: [withConfirmation],

    computed: {
      createInitiator() {
        return this.template.create_initiator?.account_id
      },

      updateInitiator() {
        return this.template.update_initiator?.account_id
      }
    },

    methods: {
      deleteItem() {
        this.$confirm({
          title: this.$t("actions.confirm_delete"),
          resolve: {
            handler: () => {
              this.$emit("delete", this.template.id)
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .action-delete
    color: $default-purple
    cursor: pointer
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

  .table-item span
    font-size: 0.8rem
</style>
